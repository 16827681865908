import AuthContext from 'context/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Container, Accordion, Row, Col, Modal, Table } from 'react-bootstrap'
import pathObj from '../utils/apiPath'
import Helper from '../utils/helpers'
import { apiGet } from '../utils/apiFetch'
import parse from 'html-react-parser'
import HomeHeader from 'components/HomeHeader'
import HomeFooter from 'components/HomeFooter'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import useToastContext from 'hooks/useToastContext'
import axios from 'axios'

function Home () {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const notification = useToastContext()
  const { language, setUser } = useContext(AuthContext)
  const [aboutus, setAboutus] = useState()
  const [contactus, setContactus] = useState()
  const [faqs, setFaqs] = useState()
  // eslint-disable-next-line no-unused-vars
  const [codeResult, setCodeResult] = useState()
  const [show, setShow] = useState(false)

  const [settings, setSettings] = useState([])
  const [showMoneyTransferModal, setShowMoneyTransferModal] = useState(false)
  const [showMoneyTransferModalMerchant, setShowMoneyTransferModalMerchant] = useState(false)
  const [showCommisionModal, setShowCommisionModal] = useState(false)
  const [showCommisionModalMerchant, setShowCommisionModalMerchant] = useState(false)
  const [showLoyaltyPointsModal, setShowLoyaltyPointsModal] = useState(false)

  const getStaticContent = async () => {
    const pathContent = pathObj.staticContentSlug
    const resultAbout = await apiGet(`${pathContent}=about-us`)
    const resultContact = await apiGet(`${pathContent}=contact-us`)
    const resultFAQ = await apiGet(pathObj.getFaqs)
    const settingsResult = await apiGet(pathObj.getSettings)

    setAboutus(resultAbout?.data?.results?.content)
    setContactus(resultContact?.data?.results?.content)
    setFaqs(resultFAQ?.data?.results)
    setSettings(settingsResult.data.results)
  }
  useEffect(() => {
    getStaticContent()
  }, [language])

  const ssoLogin = async queryToken => {
    console.log('before', queryToken)
    const { data } = await axios.post(
      process.env.REACT_APP_API_BASE_URL + pathObj.ssoLogin,
      {
        device_token: ''
      },
      { headers: { Authorization: 'Bearer ' + queryToken.slice(0) } }
    )
    if (data.success) {
      const token = data?.results?.token || null
      const refresh_token = data?.results?.refresh_token || null
      localStorage.setItem('token', token)
      localStorage.setItem('refresh_token', refresh_token)
      setUser(jwt_decode(token))
      notification.success(data.message)
      navigate('/dashboard')
    }
  }
  
  useEffect(() => {
    console.log('location', Helper.getParameterByName('access_token'))
    const token = Helper.getParameterByName('access_token')
    ssoLogin(token)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => setShow(false)
  const handleMoneyTransferClose = () => setShowMoneyTransferModal(false)
  const handleMoneyTransferMerchantClose = () => setShowMoneyTransferModalMerchant(false)
  const handleCommisionClose = () => setShowCommisionModal(false)
  const handleCommisionMerchantClose = () => setShowCommisionModalMerchant(false)
  const handleLoyaltyPointsClose = () => setShowLoyaltyPointsModal(false)

  return (
    <div>
      <HomeHeader />
      <div className='banner-part'>
        <Container>
          <div className='banner-content-row'>
            <div className='banner-caption'>
              <h1>{t('HOME_SHOP')}</h1>
              <p>
                {t('HOME_SHOP_LINE_1')}
                <br />
                {t('HOME_SHOP_LINE_2')}
                <br />
                {t('HOME_SHOP_LINE_3')}
              </p>

              <div className='download-link'>
                <strong>{t('HOME_AVAILABLE_ON')} </strong>
                <a href='https://apps.apple.com/al/app/alpay/id1624445869' target='_blank' rel="noreferrer">
                  <img src='../images/appstore.png' alt='' />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=com.app.alpay'
                  target='_blank' rel="noreferrer"
                >
                  <img src='../images/googleplay.png' alt='' />
                </a>
              </div>
            </div>

            <div className='banner-main-img'>
              <img
                src={
                  language === 1
                    ? '../images/new/banner-top.png'
                    : './images/new/al_banner-top.png'
                }
                alt=''
              />
            </div>
          </div>
        </Container>
      </div>

      <section className='crytech-feature'>
        <Container>
          <Row>
            <Col lg={4}>
              <div className='feature-img'>
                <figure>
                  <img
                    alt=''
                    src={
                      language === 1
                        ? '../images/players.png'
                        : './images/playersAl.png'
                    }
                  />
                </figure>
              </div>
            </Col>

            <Col lg={8}>
              <div className='feature-points'>
                <h2 className='section-title'>
                  {t('HOME_APP_FEATURE')}{' '}
                  <span className='d-block'>{t('HOME_SIMPLE')}</span>
                </h2>
                <ul>
                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE1')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE1')}</p>
                  </li>

                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE2')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE2')}</p>
                  </li>

                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE3')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE3')}</p>
                  </li>
                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE4')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE4')}</p>
                  </li>
                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE5')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE5')}</p>
                  </li>
                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE6')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE6')}</p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='app-showcase'>
        <Container>
          <h2 className='section-title text-center'>{t('HOME_APP_SCREENS')}</h2>
          <div className='text-center'>
            <img
              alt=''
              src={
                language === 1
                  ? '../images/new/app_screen.png'
                  : '../images/new/app_screenAl.png'
              }
            />
          </div>
        </Container>
      </section>

      <section
        className='aboutus-sec py-lg-5 py-md-3 py-2 text-center'
        id='aboutus'
      >
        <Container>{aboutus ? parse(aboutus) : null}</Container>
      </section>

      <section className='faq-sec'>
        <Container className=''>
          <h2 className='section-title text-center mt-5'>{t('FAQ')}</h2>
          <div className='row'>
            <Col md={7}>
              <Accordion defaultActiveKey='0'>
                {faqs?.map((item, i) => {
                  return (
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header>{item?.title}</Accordion.Header>
                      <Accordion.Body>{item?.content}</Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </Col>
            <Col md={5}>
              <div className='ms-lg-3 text-center text-lg-end'>
                <img
                  src={
                    language === 1
                      ? '../images/new/faq-crypt.png'
                      : '../images/new/al-faq-crypt.png'
                  }
                  alt=''
                />
              </div>
            </Col>
          </div>
        </Container>
      </section>

      <section className='bills-limits-sec bg-white pt-5'>
      <Container className=''>
          <h2 className='section-title text-center'>{t('BILLS_LIMITS')}</h2>
          <div className='row align-items-center'>
            <Col md={6} lg={4} className='my-2'>
                <div className='align-items-center d-flex flex-column justify-content-center text-center bill_limit_card'>
                  <img src='../images/new/money_transfer.png' alt={t('MONEY_TRANSFER_SETTINGS')} />
                  <h3 className='mb-2'>{t('MONEY_TRANSFER_SETTINGS')}</h3>
                  <h6>{t('MONEY_TRANSFER_SETTINGS_DESC')}</h6>
                  <div className='d-flex justify-content-center align-items-center gap-2 mt-2'>
                    <button className='button btn btn-light bg-white text-theme p-2 rounded-2' onClick={() => {setShowMoneyTransferModal(true)}}>{t('CLIENT')}</button>
                    <button className='button btn btn-light bg-white p-2 rounded-2' onClick={() => {setShowMoneyTransferModalMerchant(true)}}>{t('MERCHANT')}</button>
                  </div>
                </div>
                
                <Modal
                  show={showMoneyTransferModal}
                  onHide={handleMoneyTransferClose}
                  centered
                  className='verify_modal track_modal'
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t('MONEY_TRANSFER_SETTINGS')} ({t('CLIENT')})</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>{t('ACTION')}</th>
                          <th className='text-end'>{t('VALUE')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td className='text-start'>{t('SETTINGS_MINIMUM_WITHDRAWAL_AMOUNT')}</td>             <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.minimum_withdrawal_request)}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_MAXIMUM_WITHDRAWAL_AMOUNT')}</td>             <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.maximum_withdrawal_request)}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_ADD_FIRST_MONEY_LIMIT')}</td>                 <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.add_first_money_limit)}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_ADD_MONEY_LIMIT')}</td>                       <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.add_money_limit)}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_ADD_MONEY_LIMIT_MAXIMUM')}</td>               <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.add_money_limit_maximum)}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_TRANSFER_MONEY_TO_BANK_LIMIT')}</td>          <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.transfer_money_limit)}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_TRANSFER_MONEY_TO_BANK_MAXIMUM_LIMIT')}</td>  <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.transfer_money_maximum_limit)}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('O_TRANSACTION_LIMIT')}</td>                            <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.transactionLimit)}</b> LEK</td></tr>
                      </tbody>
                    </Table>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={showMoneyTransferModalMerchant}
                  onHide={handleMoneyTransferMerchantClose}
                  centered
                  className='verify_modal track_modal'
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t('MONEY_TRANSFER_SETTINGS')} ({t('MERCHANT')})</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>{t('ACTION')}</th>
                          <th className='text-end'>{t('VALUE')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td className='text-start'>{t('SETTINGS_MINIMUM_WITHDRAWAL_AMOUNT')}</td>             <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.minimum_withdrawal_request_business)}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_MAXIMUM_WITHDRAWAL_AMOUNT')}</td>             <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.maximum_withdrawal_request_business)}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_ADD_FIRST_MONEY_LIMIT')}</td>                 <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.add_first_money_limit_business)}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_ADD_MONEY_LIMIT')}</td>                       <td style={{color: '#6236ff'}} className='text-end'><b>{new Intl.NumberFormat().format(settings.add_money_limit_business)}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_ADD_MONEY_LIMIT_MAXIMUM')}</td>               <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.add_money_limit_maximum_business === 0) ? t('NO_LIMIT') : new Intl.NumberFormat().format(settings.add_money_limit_maximum_business) + ' LEK'}</b></td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_TRANSFER_MONEY_TO_BANK_LIMIT')}</td>          <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.transfer_money_limit_business === 0) ? t('NO_LIMIT') : new Intl.NumberFormat().format(settings.transfer_money_limit_business) + ' LEK'}</b></td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_TRANSFER_MONEY_TO_BANK_MAXIMUM_LIMIT')}</td>  <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.transfer_money_maximum_limit_business === 0) ? t('NO_LIMIT_WITHIN_BALANCE') : new Intl.NumberFormat().format(settings.transfer_money_maximum_limit_business) + ' LEK'}</b></td></tr>
                        <tr><td className='text-start'>{t('O_TRANSACTION_LIMIT')}</td>                            <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.transactionLimitBusiness === 0) ? t('NO_LIMIT_WITHIN_BALANCE') : new Intl.NumberFormat().format(settings.transactionLimitBusiness) + ' LEK'}</b></td></tr>
                      </tbody>
                    </Table>
                  </Modal.Body>
                </Modal>
                
                
            </Col>
            <Col md={6} lg={4} className='my-2'>
                <div className='align-items-center d-flex flex-column justify-content-center text-center bill_limit_card'>
                  <img src='../images/new/commission.png' style={{height: '6rem', marginBottom: '1rem'}} alt={t('COMMISION_SETTINGS')} />
                  <h3 className='mb-2'>{t('COMMISION_SETTINGS')}</h3>
                  <h6>{t('COMMISION_SETTINGS_DESC')}</h6>
                  <div className='d-flex justify-content-center align-items-center gap-2 mt-2'>
                    <button className='button btn btn-light bg-white text-theme p-2 rounded-2' onClick={() => {setShowCommisionModal(true)}}>{t('CLIENT')}</button>
                    <button className='button btn btn-light bg-white p-2 rounded-2' onClick={() => {setShowCommisionModalMerchant(true)}}>{t('MERCHANT')}</button>
                  </div>
                </div>

                <Modal
                  show={showCommisionModal}
                  onHide={handleCommisionClose}
                  centered
                  className='verify_modal track_modal'
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t('COMMISION_SETTINGS')} ({t('CLIENT')})</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>{t('ACTION')}</th>
                          <th className='text-end'>{t('VALUE')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td className='text-start'>{t('SETTINGS_CASH_OUT_COMMISSION')}</td>                                     <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.cash_out_commission === 0) ? t('FREE') : settings.cash_out_commission + ' %'}</b></td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_CASH_TRANSFER_COMMISION')}</td>                                 <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.cash_transfer_commision === 0) ? t('FREE') : settings.cash_transfer_commision + ' %'}</b></td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_OPERATOR_CASH_IN_COMMISSION')}</td>                             <td style={{color: '#6236ff'}} className='text-end'><b>{settings.operator_cash_in_commission}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_OPERATOR_CASH_IN_COMMISSION_PAST_MINIMUM_PERCENTAGE')}</td>     <td style={{color: '#6236ff'}} className='text-end'><b>{settings.operator_cash_in_commission_past_minimum_percentage}</b> %</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_PHONE_OPERATOR_BILL_COMMISION')}</td>                           <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.phone_operator_bill_commision === 0) ? t('FREE') : settings.phone_operator_bill_commision + ' %'}</b></td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_INTERNET_BILL_COMMISION')}</td>                                 <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.internet_bill_commision === 0) ? t('FREE') : settings.internet_bill_commision + ' %'}</b></td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_TRANSACTION_FEE')}</td>                                         <td style={{color: '#6236ff'}} className='text-end'><b>{settings.transaction_fee}</b> LEK</td></tr>
                      </tbody>
                    </Table>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={showCommisionModalMerchant}
                  onHide={handleCommisionMerchantClose}
                  centered
                  className='verify_modal track_modal'
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t('COMMISION_SETTINGS')} ({t('MERCHANT')})</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>{t('ACTION')}</th>
                          <th className='text-end'>{t('VALUE')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td className='text-start'>{t('SETTINGS_CASH_OUT_COMMISSION')}</td>                                     <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.cash_out_commision_business === 0) ? t('FREE') : settings.cash_out_commision_business + ' %'}</b></td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_CASH_TRANSFER_COMMISION')}</td>                                 <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.cash_transfer_commision_business === 0) ? t('FREE') : settings.cash_transfer_commision_business + ' %'}</b></td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_OPERATOR_CASH_IN_COMMISSION')}</td>                             <td style={{color: '#6236ff'}} className='text-end'><b>{settings.operator_cash_in_commission_business}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_OPERATOR_CASH_IN_COMMISSION_PAST_MINIMUM_PERCENTAGE')}</td>     <td style={{color: '#6236ff'}} className='text-end'><b>{settings.operator_cash_in_commission_past_minimum_percentage_business}</b> %</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_PHONE_OPERATOR_BILL_COMMISION')}</td>                           <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.phone_operator_bill_commision_business === 0) ? t('FREE') : settings.internet_bill_commision_business + ' %'}</b></td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_INTERNET_BILL_COMMISION')}</td>                                 <td style={{color: '#6236ff'}} className='text-end'><b>{(settings.internet_bill_commision_business === 0) ? t('FREE'): settings.internet_bill_commision_business + ' %'}</b></td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_TRANSACTION_FEE')}</td>                                         <td style={{color: '#6236ff'}} className='text-end'><b>{settings.transaction_fee_business}</b> LEK</td></tr>
                      </tbody>
                    </Table>
                  </Modal.Body>
                </Modal>

            </Col>
            <Col md={6} lg={4} className='my-2'>
                <div className='align-items-center d-flex flex-column justify-content-center text-center bill_limit_card' onClick={() => {setShowLoyaltyPointsModal(true)}}>
                  <img src='../images/new/loyalty_points.svg' style={{marginBottom: '1rem'}} alt={t('LOYALTY_POINTS_SETTINGS')} />
                  <h3 className='mb-2'>{t('LOYALTY_POINTS_SETTINGS')}</h3>
                  <h6>{t('LOYALTY_POINTS_SETTINGS_DESC')}</h6>
                </div>


                <Modal
                  show={showLoyaltyPointsModal}
                  onHide={handleLoyaltyPointsClose}
                  centered
                  className='verify_modal track_modal'
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{t('LOYALTY_POINTS_SETTINGS')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>{t('ACTION')}</th>
                          <th className='text-end'>{t('VALUE')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td className='text-start'>{t('SETTINGS_NUMBER_OF_LOYALTY_POINTS')}</td>                        <td style={{color: '#6236ff'}} className='text-end'><b>{settings.conversion_point}</b> {t('POINTS')}</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_CONVERSION_RATE_OF_LOYALTY_POINT')}</td>                <td style={{color: '#6236ff'}} className='text-end'><b>{settings.conversion_rate}</b> LEK</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_UTILITY_LOYALTY_POINT')}</td>                           <td style={{color: '#6236ff'}} className='text-end'><b>{settings.utilityLoyaltyPoint}</b> %</td></tr>
                        <tr><td className='text-start'>{t('SETTINGS_THRESHOLD_VALUE_OF_REDEEM_POINT')}</td>                 <td style={{color: '#6236ff'}} className='text-end'><b>{settings.threshold_value_to_redeem_point}</b> LEK</td></tr>
                      </tbody>
                    </Table>
                  </Modal.Body>
                </Modal>
            </Col>
          </div>
        </Container>
      </section>

      {/* contactus */}
      <section
        className='aboutus-sec py-lg-5 py-md-3 py-2 text-center'
        id='contactus'
      >
        <Container>
          <h2 className='section-title text-center'>{t('FOOTER_CONTACT_US')}</h2>
          {contactus ? parse(contactus) : null}
        </Container>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className='verify_modal track_modal'
      >
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-5'>
          <img src='./images/check.png' alt='' className='mb-4 opacity-1' />
          <p className='text-center'>
            {codeResult?.success ? codeResult.message : codeResult}
          </p>
          <p>
            {codeResult?.success &&
              'Our team review your message and get back to you'}
          </p>
        </Modal.Body>
      </Modal>

      <HomeFooter />
    </div>
  )
}

export default Home
